import { useState, useEffect } from "react";
import { Grid } from "@mantine/core";
import {
  FaHome,
  FaPhoneAlt,
  FaEnvelopeOpenText,
  FaLinkedin,
  FaGitlab,
  FaGithub,
} from "react-icons/fa";

import { ContactModal } from "./contactModal";

export function Introduction() {
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    if (modalOpened) {
      setTimeout(() => {
        const closeModal = (event) => {
          if (event.target.id === "modal") {
            setModalOpened(false);
            window.removeEventListener("click", closeModal);
          }
        };
        window.addEventListener("click", closeModal);
      }, 16);
    }
  }, [modalOpened]);

  return (
    <>
      <div id="introduction">
        <div className="identity">
          <h1>{process.env.REACT_APP_FULL_NAME}</h1>
          <h2>{process.env.REACT_APP_TITLE}</h2>
        </div>

        <Grid className="contacts" grow gutter="xs">
          <Grid.Col xs={12} sm={6} md={4}>
            <FaHome size={16} /> {process.env.REACT_APP_LOCATION}
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={4}>
            <FaPhoneAlt size={16} /> {process.env.REACT_APP_PHONE}
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={4}>
            <FaEnvelopeOpenText
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => setModalOpened(true)}
            />{" "}
            {process.env.REACT_APP_MAIL}
          </Grid.Col>
          <Grid.Col
            xs={12}
            sm={6}
            md={4}
            style={{ cursor: "pointer" }}
            onClick={() => {
              const linkedInName = process.env.REACT_APP_FULL_NAME.split(" ")
                .join("-")
                .toLowerCase();
              window.open(
                `https://www.linkedin.com/in/${linkedInName}`,
                "_blank"
              );
            }}
          >
            <FaLinkedin size={16} /> {process.env.REACT_APP_FULL_NAME}
          </Grid.Col>
          {process.env.REACT_APP_GITHUB_NAME && (
            <Grid.Col
              xs={12}
              sm={6}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `https://www.github.com/${process.env.REACT_APP_GITHUB_NAME}`,
                  "_blank"
                )
              }
            >
              <FaGithub size={16} /> {process.env.REACT_APP_GITHUB_NAME}
            </Grid.Col>
          )}
          {process.env.REACT_APP_GITLAB_NAME && (
            <Grid.Col
              xs={12}
              sm={6}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `https://git.cenne.xyz/${process.env.REACT_APP_GITLAB_NAME}`,
                  "_blank"
                )
              }
            >
              <FaGitlab size={16} /> {process.env.REACT_APP_GITLAB_NAME}
            </Grid.Col>
          )}
        </Grid>

        {modalOpened && (
          <ContactModal closeModal={() => setModalOpened(false)} />
        )}
      </div>
    </>
  );
}
