import React, { createContext, createRef, useEffect, useState } from "react";

import { CustomTimeline } from "./timeline";
import { Aside } from "./aside";

import experiences from '../../assets/data/experiences.json';
import trainings from '../../assets/data/trainings.json';
import achievements from '../../assets/data/achievements.json';


const timeline = [
    ...experiences,
    ...trainings,
    ...achievements
]

function getDefaultTimeline() {
    return timeline.map((item) => ({
        ...item,
        nodeRef: createRef(null)
    }))
}

function isSameTimeline(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  return a.every(function(elem, index) {
    return elem === b[index];
  });
}

export const BadgeFilterContext = createContext();

export function Main() {
  const [currentFilter, setCurrentFilter] = useState(null);
  const [currentTimeline, setCurrentTimeline] = useState([]);
  const [currentSortedTimeline, setCurrentSortedTimeline] = useState([]);

  useEffect(() => {
    setCurrentTimeline(getDefaultTimeline())
  }, [setCurrentTimeline])

  useEffect(() => {
    if (currentTimeline.length > 0) {
      setCurrentSortedTimeline(currentTimeline.sort((a, b) => {
        const dateA = new Date(a.date.start.y, a.date.start.m ? a.date.start.m - 1 : 0, a.date.start.d ? a.date.start.d : 1);
        const dateB = new Date(b.date.start.y, b.date.start.m ? b.date.start.m - 1 : 0, b.date.start.d ? b.date.start.d : 1);

        return dateB - dateA
      }));
    }
  }, [currentTimeline, currentSortedTimeline]);

  useEffect(() => {
    if (currentFilter !== null) {
      const filteredTimeline = currentTimeline.filter((item) => {
        if (!item.badges) {
          return false
        }
        return item.badges.find((badge) => (new RegExp(currentFilter.toLowerCase(), 'gi').test(badge.label.toLowerCase()))) !== undefined
      });

      if (currentTimeline.length > filteredTimeline.length) {

      }

      if (filteredTimeline.length > 0) {
        if (!isSameTimeline(filteredTimeline, currentTimeline)) {
          setCurrentTimeline(filteredTimeline);
        }
      } else if (!isSameTimeline(currentTimeline, timeline)) {
        setCurrentTimeline(timeline);
      }
    } else {
      if (!isSameTimeline(currentTimeline, timeline)) {
        setCurrentTimeline(timeline);
      }
    }
  }, [currentFilter, currentSortedTimeline, currentTimeline])

  const toggleFilter = (filter) => {
    if (currentFilter) {
      if (currentFilter !== filter) {
          setCurrentFilter(filter)
      } else {
          setCurrentFilter(null)
      }
    } else {
      setCurrentFilter(filter)
    }
  };

  return (
    <main>
      <BadgeFilterContext.Provider value={{ toggleFilter, currentSortedTimeline, currentFilter }}>
        <Aside />
        <CustomTimeline />
      </BadgeFilterContext.Provider>
    </main>
  );
}
