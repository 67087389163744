import React, { useContext } from 'react';
import { Timeline, Text, Badge, Group } from '@mantine/core';
// import * as icons from '@react-icons/all-files'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FaLink, FaRobot } from 'react-icons/fa';
import { BadgeFilterContext } from './';
import { RiTeamFill } from 'react-icons/ri';
import { LiaStoreSolid, LiaStoreAltSolid } from 'react-icons/lia';
import { SiTestinglibrary, SiJavascript } from 'react-icons/si';
import { AiFillWindows, AiFillStar } from 'react-icons/ai';
import { RiComputerFill } from 'react-icons/ri';
import { BsGearWideConnected } from 'react-icons/bs';
import { MdSchool } from 'react-icons/md';
import { GiFactory } from 'react-icons/gi';
import { TbBallBowling } from "react-icons/tb";

const softSkills = {
    'Polyvalence': { color: "blue" },
    'Leadership': { color: "yellow" },
    'Autonomie': { color: "green" },
    'Vivacité': { color: "orange" },
    'Sociabilité': { color: "red" },
    'Acuité': { color: "gray" },
    'Ingéniosité': { color: "indigo" },
    'Collaboration': { color: 'teal' }
}

function getLocaleDateString(date) {
    return `${date.toLocaleDateString('fr', { month: 'long' })} ${date.getFullYear()}`;
}
function formatDate(date1, date2) {
  if (date2 === undefined) {
    date2 = new Date();
    
  }
  return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
      ? getLocaleDateString(date1)
      : `${getLocaleDateString(date1)} — ${date2.toDateString() === (new Date()).toDateString() ? 'Aujourd\'hui' : getLocaleDateString(date2) }`;
}

function getTimelineIcon(timelineId) {
  switch (timelineId) {
    case 'allo-goodies':
      return LiaStoreAltSolid;
    case 'cms-reset-factory':
      return LiaStoreSolid;
    case 'rpg-ngv':
      return BsGearWideConnected;
    case 'website-refemi':
      return RiTeamFill;
    case 'project-evil-paradise':
      return AiFillStar;
    case 'stage-ip-label':
      return SiTestinglibrary;
    case 'as-axido':
      return AiFillWindows;
    case 'it-audika':
      return RiComputerFill;
    case 'sav-boulanger':
      return FaRobot;
    case 'study-konexio':
      return SiJavascript;
    case 'study-udemy':
      return MdSchool;
    case 'training-boulanger':
      return GiFactory;
    case 'website-laballebleue':
      return TbBallBowling;
    default:

  }
}
 
export function CustomTimeline() {
  const { toggleFilter, currentSortedTimeline } = useContext(BadgeFilterContext);

  const timelineProps = {
    active: 1,
    bulletSize: 24,
    linewidth: 2,
  }

  return (
    <div id="timeline">
      <Timeline {...timelineProps}>
        <TransitionGroup>
          {currentSortedTimeline.map((item, index) => {
            const dateStart = new Date(
              item.date.start.y,
              item.date.start.m ? item.date.start.m - 1 : 0,
              item.date.start.d ? item.date.start.d : 1
            );

            let dateEnd;
            if (item.date.end) {
              dateEnd = new Date(
                item.date.end.y,
                item.date.end.m ? item.date.end.m - 1 : 0,
                item.date.end.d ? item.date.end.d : 1
              );
            }

            const icon = getTimelineIcon(item.id);

            return (
              <CSSTransition
                key={`${index}-timeline-css-item`}
                nodeRef={item.nodeRef}
                timeout={500}
                classNames="item"
                >
                <Timeline.Item
                  key={`${index}-timeline-item`}
                  bullet={!!icon && icon({ size: 14 })}
                  title={`${item.title} — ${item.target}`}
                  lineActive={true}
                  ref={item.nodeRef}
                >
                  <Text
                    key={`${index}-text-date`}
                    color="dimmed"
                    size="xs"
                  >
                    {item.url && (
                      <>
                        <FaLink 
                          key={`${index}-text-url`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.open(item.url, '_blank')}
                        />&nbsp;&nbsp;
                      </>
                    )}
                    {`${formatDate(dateStart, dateEnd)} `}
                  </Text>
                  <Text key={`${index}-text-description`} color="dimmed" size="sm">
                    
                    {item.description}
                  </Text>

                  <Group key={`${index}-flex-badge`} spacing="xs" className="badges">
                    {item.badges && item.badges.map((badge, badgeIndex) => {
                      const badgeVariant = badge.variant ? badge.variant : softSkills[badge.label] ? 'dot' : 'outline';
                      const badgeColor = badge.color ? badge.color : softSkills[badge.label] ? softSkills[badge.label].color : 'blue';

                      return (
                        <Badge
                          key={`${badgeIndex}-badge`}
                          color={badgeColor}
                          variant={badgeVariant}
                          onClick={() => toggleFilter(badge.label)}
                          style={{ cursor: 'pointer' }}
                        >
                          {badge.label}
                        </Badge>
                      );
                    })}
                  </Group>   
                </Timeline.Item>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </Timeline>
    </div>
  );
}
