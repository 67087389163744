import React from "react";

import about from "../../../assets/data/about.json";

export function About() {

    return (
        <div id="about">
            <h3>À propos</h3>
            {about.map((paragrah, index) =>
                <p key={`p-about-${index}`}>{paragrah}</p>
            )}
        </div>
    )
}