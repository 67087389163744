import { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { GrClose } from "react-icons/gr";

export function ContactModal({ closeModal }) {
  const [statusEmailJS, setStatusEmailJS] = useState(localStorage.getItem("contacted") ? 'success' : null);
  
  const isFormDisabled = () => statusEmailJS === 'success' || statusEmailJS === 'pending' ||  localStorage.getItem("contacted");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: data.name,
          from_email: data.email,
          message: data.message,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(() => {
        setStatusEmailJS("success");
        localStorage.setItem("contacted", true);
        setTimeout(() => {
          closeModal()
        }, 2000);
      })
      .catch(() => {});

    setStatusEmailJS("pending");
  };


  return (
    <div id="modal" className="mail-modal">
      <div className="mail-modal-content">
        <div className="modal-close" onClick={closeModal}>
          <GrClose />
        </div>
        <div className="modal-header">Prenons contact !</div>
        <div className="modal-body">
          <div className="contact-me">
            <p>
              Si mon profil a retenu votre attention et que vous estimez que mes
              compétences peuvent être bénéfiques pour votre entreprise, je vous
              invite à prendre quelques minutes pour me contacter.
            </p>

            <p>
              N'hésitez pas à m'envoyer un message en utilisant le formulaire
              ci-joint. J'apprécie particulièrement lorsque les gens partagent
              avec moi leurs défis et leurs idées – cela me donne l'opportunité
              d'apprendre, de grandir et de participer à des projets
              passionnants.
            </p>

            <p>
              Votre temps est précieux, c'est pourquoi je vous assure que toute
              communication sera traitée avec le plus grand sérieux et dans les
              plus brefs délais.
            </p>

            <p>
              Au plaisir de vous lire très prochainement,
              <br />
              Andy
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>

            <label>Nom :</label>
            <input {...register("name", { required: true, disabled: isFormDisabled() })} />
            <label>E-Mail :</label>
            <input {...register("email", { required: true, disabled: isFormDisabled() })} />
            <label>Contenu du message :</label>
            <textarea {...register("message", { required: true, disabled: isFormDisabled() })} />
            
            {!!statusEmailJS ? (
              statusEmailJS === "pending" ? (
                <input className="button-pending" value="En cours..." />
              ) : (
                statusEmailJS === "error" ? (
                  <div className="error">
                    Une erreur est survenue, veuillez réessayer plus tard.
                  </div>
                ) : statusEmailJS === 'success' && (
                  <input className="button-success" value="Envoyé !" />
                )
              )
            ) : (
              <input type="submit" value="Envoyer" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
}