import { image64 } from './image64';

export function ProfilePicture() {
    return (
        <img
            src={`data:image/png;base64,${image64}`}
            alt="Selfie cat"
            style={{ zIndex: 9 }}
        />
    )
}