import { Flex, Header } from "@mantine/core";
import React from "react";
import { Introduction } from "./introduction";
import { ProfilePicture } from "./profilePicture";

export function CustomHeader() {
    return (
        <Header withBorder={false}>
            <Flex align="center" gap="xl">
                <Introduction />
                <ProfilePicture />
            </Flex>
        </Header>
    )
}