import { About } from "./about"
import { Skills } from "./skills"


export function Aside() {
    return (
        <aside>
            <About />
            <Skills type="hard" />
            <Skills type="soft" />
        </aside>
    )
}